.sidebarBody {
  position: sticky;
  top: 0;
  height: 100%;
  z-index: 1000;
}

.nav .open>a,
.nav .open>a:hover,
.nav .open>a:focus {
  background-color: transparent;
}

/*-------------------------------*/
/*           Wrappers            */
/*-------------------------------*/

#wrapper {
  padding-left: 0;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  position: relative;
  height: calc(100% - 0px);
  left: 0;
  z-index: 1001;
  background: var(--bs-light);
}

#sidebar-wrapper {
  padding: 0;
  z-index: 1000;
  width: 50px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  background-color: var(--bs-gray-200);
}
@media (max-width:900px) {
  #sidebarBody{
    width: 50px;
    }
}

#sidebar-wrapper::-webkit-scrollbar {
  display: none;
}

#wrapper.toggled #sidebar-wrapper {
  width: 220px;
}

#page-content-wrapper {
  width: 100%;
  padding-top: 70px;
}

#wrapper.toggled #page-content-wrapper {
  position: absolute;
  margin-right: -220px;
}

/*-------------------------------*/
/*     Sidebar nav styles        */
/*-------------------------------*/
/* .navbar {
  padding: 0;
} */

.sidebar-nav {
  width: 220px;
  margin: 0;
  padding: 0;
  list-style: none;
}

.sidebar-nav li~li {
  border-top: 1px solid var(--bs-gray-200);
}

.sidebar-nav li {
  justify-content: center;
  position: relative;
  line-height: 34px;
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: center;
  background-color: var(--bs-light);
  position: relative;
  cursor: pointer;
}
.commingSoon{
  position: absolute;
  background-color: #fe28a2;
  color:white;
  border: 1px solid #fe28a2;
  border-radius: 5px;
  line-height: 1;
  font-size: 10px;
  display: flex;
  align-items: center;
  padding: 1px 3px;
  top: 3px;
  right: 0;
}
.commingSoon span{
  font-size: 12px;
}
.dropList{
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  width: 100%;

}
.sidebar-nav li:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  height: 100%;
  width: 3px;
  background-color: #1c1c1c;
  transition: width 0.2s ease-in;
  -moz-transition: width 0.2s ease-in;
  -ms-transition: width 0.2s ease-in;
  transition: width 0.2s ease-in;
  cursor: pointer;
}

.sidebar-nav li::before {
  background-color: var(--bs-gray-200);
}

.sidebar-nav li:hover:before,
.sidebar-nav li.open:hover:before {
  width: 100%;
  transition: width 0.2s ease-in;
  -moz-transition: width 0.2s ease-in;
  -ms-transition: width 0.2s ease-in;
  transition: width 0.2s ease-in;
}

.sidebar-nav li>div {
  display: block;
  color: var(--bs-secondary);
  text-decoration: none;
  display: flex;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;
}

.sidebar-nav li:hover>a,
.sidebar-nav li:active>a,
.sidebar-nav li:focus>a,
.sidebar-nav li.open:hover>a,
.sidebar-nav li.open:active>a,
.sidebar-nav li.open:focus>a {
  color: var(--bs-dark);
  text-decoration: none;
  /* background-color: transparent; */
}

.sidebar-header {
  text-align: center;
  font-size: 20px;
  width: 100%;
  display: inline-block;
  position: sticky;
  top: 0;
  z-index: 1002;
}

.sidebar-brand {
  height: 50px;
  position: relative;
  background: linear-gradient(to right bottom, #2f3441 50%, #212531 50%);
  padding: 0.2em;
  display: flex;
  align-items: center;
  position: relative;
}

.sidebar-brand i {
  position: absolute;
  top: -5px;
  right: -1px;
  font-size: 1em;
  font-style: normal;
}

/* animate text profile */


#two {
  width: 160px;
  padding-left: 10px;
  padding-right: 10px;
  overflow: hidden;
}

.ani-center {
  display: grid;
  gap: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-8%, -50%);
}

.ani-text-container {
  padding: 5px 10px;
  min-width: 0;
  font-size: 1em;
  color: #708090;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  border: 1px solid #708090;
  border-radius: 5px;
}

span {
  display: inline-block;
}

.animate {
  position: relative;
  /* animation: leftright 3s infinite alternate ease-in-out; */
  max-width: 100%;
}


@keyframes leftright {

  0%,
  20% {
    transform: translateX(0%);
    left: 0%;
  }

  80%,
  100% {
    transform: translateX(-100%);
    left: 100%;
  }
}

.ani-fader {
  position: absolute;
  top: 0;
  height: 100%;
  width: 25px;
}

.fader-left {
  left: 0;
  background: linear-gradient(to left,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1));
}

.fader-right {
  right: 0;
  background: linear-gradient(to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1));
}





.sidebar-brand a {
  color: #ddd;
}

.sidebar-brand a:hover {
  color: #fff;
  text-decoration: none;
}

.dropdown-header {
  text-align: center;
  font-size: 1em;
  color: #ddd;
  background: #212531;
  background: linear-gradient(to right bottom, #2f3441 50%, #212531 50%);
}

.sidebar-nav .dropdown-menu {
  position: relative;
  width: 100%;
  padding: 0;
  margin: 0;
  border-radius: 0;
  border: none;
  box-shadow: none;
}

.dropdown-menu.show {
  top: 0;
  background-color: var(--bs-gray-100);
}

.dropdown-menu.show li::before {
  content: unset !important;
}


/*-------------------------------*/
/*       Hamburger-Cross         */
/*-------------------------------*/

.hamburger {
  position: absolute;
  top: 8px;
  left: 7px;
  z-index: 1000;
  display: block;
  width: 32px;
  height: 32px;
  background: transparent;
  border: none;
}

.hamburger:hover,
.hamburger:focus,
.hamburger:active {
  outline: none;
}
.toggled #textAnimate{
  display: block;
}
#textAnimate{
  display: none;
}
.hamburger.is-closed:before {
  content: "";
  display: block;
  width: 100px;
  font-size: 14px;
  color: #fff;
  line-height: 32px;
  text-align: center;
  opacity: 0;
  transform: translate3d(0, 0, 0);
  transition: all 0.35s ease-in-out;
}

.hamburger.is-closed:hover:before {
  opacity: 1;
  display: block;
  transform: translate3d(-100px, 0, 0);
  transition: all 0.35s ease-in-out;
}

.hamburger.is-closed .hamb-top,
.hamburger.is-closed .hamb-middle,
.hamburger.is-closed .hamb-bottom,
.hamburger.is-open .hamb-top,
.hamburger.is-open .hamb-middle,
.hamburger.is-open .hamb-bottom {
  position: absolute;
  left: 0;
  height: 4px;
  width: 100%;
}

.hamburger.is-closed .hamb-top,
.hamburger.is-closed .hamb-middle,
.hamburger.is-closed .hamb-bottom {
  background-color: var(--bs-gray-200)
}

.hamburger.is-closed .hamb-top {
  top: 5px;
  transition: all 0.35s ease-in-out;
}

.hamburger.is-closed .hamb-middle {
  top: 50%;
  margin-top: -2px;
}

.hamburger.is-closed .hamb-bottom {
  bottom: 5px;
  transition: all 0.35s ease-in-out;
}

.hamburger.is-closed:hover .hamb-top {
  top: 0;
  transition: all 0.35s ease-in-out;
}

.hamburger.is-closed:hover .hamb-bottom {
  bottom: 0;
  transition: all 0.35s ease-in-out;
}

.hamburger.is-open .hamb-top,
.hamburger.is-open .hamb-middle,
.hamburger.is-open .hamb-bottom {
  background-color: var(--bs-gray-400);
}

.hamburger.is-open .hamb-top,
.hamburger.is-open .hamb-bottom {
  top: 50%;
  margin-top: -2px;
}

.hamburger.is-open .hamb-top {
  transform: rotate(45deg);
  transition: transform 0.2s cubic-bezier(0.73, 1, 0.28, 0.08);
}

.hamburger.is-open .hamb-middle {
  display: none;
}

.hamburger.is-open .hamb-bottom {
  transform: rotate(-45deg);
  transition: transform 0.2s cubic-bezier(0.73, 1, 0.28, 0.08);
}

.hamburger.is-open:before {
  content: "";
  display: block;
  width: 100px;
  font-size: 14px;
  color: #fff;
  line-height: 32px;
  text-align: center;
  opacity: 0;
  transform: translate3d(0, 0, 0);
  transition: all 0.35s ease-in-out;
}

.hamburger.is-open:hover:before {
  opacity: 1;
  display: block;
  transform: translate3d(-100px, 0, 0);
  transition: all 0.35s ease-in-out;
}

/*-------------------------------*/
/*            sideBarOverlay            */
/*-------------------------------*/

.sideBarOverlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(250, 250, 250, 0.8);
  z-index: 1;
}

.sidebarBody .dropdown-menu li a {
  margin: 0;
  ;
}

.sidebarBody .navbar {
  align-items: flex-start;
}

.sidebar-brand img {
  width: 40px;
  height: 40px;
  object-fit: contain;
}

.sidbaravatar {
  height: 100%;
  width: 40%;
  font-size: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--bs-light);
}

.sidbarProfilehead {
  padding: 0.2em;
  display: flex;
  align-items: center;
  z-index: 2;
  width: 85%;
  justify-content: flex-end;
}

.liStatic{
  width: 50px !important;
  height: 51px;
  font-size: 2em;
  color: var(--bs-gray-700);
  background-color: var(--bs-gray-200);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-style: normal;
  align-self: flex-start;
  cursor: pointer;
  flex-shrink: 0;
}
.liStatic img{
  object-fit: contain;
  width: 100%;
  height: 100%;
}
#userSettings{
  background-color: var(--bs-gray-700);
  color: var(--bs-body-bg);
  justify-content: flex-start;
  width: 50px;
  transition: all 0.5s ease;
  position: fixed;
  bottom: -0px;
  z-index: 10000;
}
#userSettings.toggled{
  width: 220px !important;
}
#userSettings a{
display: flex;
align-items: center;
}
#userSettings i{
  background-color: inherit;
  color: var(--bs-body-bg);
}
#userSettings div{
  color: var(--bs-body-bg);
}
.toggled .dropdown ul,.toggled .dropdown ul li{
  background-color: var(--bs-light);
}
.dropdown ul,.dropdown ul li{
  background-color: var(--bs-gray-200);
}
/* /////////////////////////////////////////////////////////////// */

/* <div id="container-floating">
  <div class="nd4 nds">
  </div>

  <div class="nd3 nds"></div>

  <div class="nd1 nds">
   
  </div>

  <div id="floating-button">
    
  </div>
</div> */
/* ///////////////////////////////////////// */
/* #floating-button {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background: #db4437;
  position: fixed;
  bottom: 30px;
  right: 30px;
  cursor: pointer;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
}

#container-floating {
  position: fixed;
  width: 70px;
  height: 70px;
  bottom: 30px;
  right: 30px;
  z-index: 50px;
}

#container-floating:hover {
  height: 400px;
  width: 90px;
  padding: 30px;
}

.nds {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: fixed;
  z-index: 300;
  transform: scale(0);
  cursor: pointer;
}

.nd1 {
  background: #d3a411;
  right: 40px;
  bottom: 120px;
  animation-delay: 0.2s;
  animation: bounce-out-nds 0.3s linear;
  animation-fill-mode: forwards;
}

.nd3 {
  background: #3c80f6;
  right: 40px;
  bottom: 180px;
  animation-delay: 0.15s;
  animation: bounce-out-nds 0.15s linear;
  animation-fill-mode: forwards;
}

.nd4 {
  background: #ba68c8;
  right: 40px;
  bottom: 240px;
  animation-delay: 0.1s;
  animation: bounce-out-nds 0.1s linear;
  animation-fill-mode: forwards;
}

@keyframes bounce-nds {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes bounce-out-nds {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0);
  }
}

#container-floating:hover .nds {
  animation: bounce-nds 0.1s linear;
  animation-fill-mode: forwards;
}

#container-floating:hover .nd3 {
  animation-delay: 0.08s;
}
#container-floating:hover .nd4 {
  animation-delay: 0.15s;
}
#container-floating:hover .nd5 {
  animation-delay: 0.2s;
}

 */
