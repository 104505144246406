.modalE1{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1055;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
}
.listSharedAddress{
width: 100%;
height: max-content;
min-height: 40px;
max-height: 150px;
overflow-y: scroll;
padding: 0;
}
.listSharedAddress li{
    min-height: 40px;
}
.elipsed{
    flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.blink {
    animation: blinker 1s linear infinite ;
  }
  
  @keyframes blinker {
    70% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  .deleted::after{
    content: "delete_forever";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    /* font-family: 'Poppins-Regular'; */
    /* font-size: 0.5em; */
    color: var(--bs-danger);
    background: hsla(0,0%,100%,.2);
    backdrop-filter: blur(2px);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    border: 1px solid var(--bs-gray-200);
  }