
html {
  scroll-behavior: smooth;
}
body{
    font-family: 'Poppins-Regular' !important;
    line-height: 1.5;
    font-size: 14px;
    background-color: var(--bs-body-bg);
}
::-webkit-scrollbar {
    width: 5px;
  }
::-webkit-scrollbar-thumb {
    background: var(--bs-gray-400); 
    border-radius: 10px;
  }
::-webkit-scrollbar-thumb:hover {
    background: var(--bs-gray); 
  }
input:focus-visible{
    outline: 0px;
  }
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}
  @font-face {
    font-family: "Material Icons Extended";
    src: url(../fonts/materialicon.woff2) format('woff2');
  }
  
  @font-face {
    font-family: "Material Icons outLine";
    src: url(../fonts/MaterialIconsOutlined-Regular.otf) format('woff2');
  }
  @font-face {
    font-family: "Poppins-Black";
    src: url(../fonts/Poppins-Black.ttf) format('woff2');
  }
  @font-face {
    font-family: "Poppins-Bold";
    src: url(../fonts/Poppins-Bold.ttf) format('woff2');
  }
  @font-face {
    font-family: "Poppins-ExtraBold";
    src: url(../fonts/Poppins-ExtraBold.ttf) format('woff2');
  }
  @font-face {
    font-family: "Poppins-Light";
    src: url(../fonts/Poppins-Light.ttf) format('woff2');
  }
  @font-face {
    font-family: "Poppins-Medium";
    src: url(../fonts/Poppins-Medium.ttf) format('woff2');
  }
  @font-face {
    font-family: "Poppins-Regular";
    src: url(../fonts/Poppins-Regular.ttf) format('woff2');
  }
  @font-face {
    font-family: "Poppins-SemiBold";
    src: url(../fonts/Poppins-SemiBold.ttf) format('woff2');
  }
  @font-face {
    font-family: "Poppins-Thin";
    src: url(../fonts/Poppins-Thin.ttf) format('woff2');
  }
  @font-face {
    font-family: "fontawesome";
    src: url(../fonts/fa-solid-900.woff2) format('woff2');
  }
  @font-face {
    font-family: "fontawesome-brand";
    src: url(../fonts/fa-brands-400.ttf) format('woff2');
  }
  
  a{
    text-decoration: none;
  }
  li{
    list-style: none;
  }
  .btn:focus{
    box-shadow: none !important;
    
  }
  .icon{
  font-family: 'Material Icons outLine';
}
.btn-primary a{
  color:var(--bs-gray-100);
}
.unit{
  width: 16px;
}
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; 
}

.tooltip .tooltiptext {
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  top: -50px;
  left: 0;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
.topLoading{
  position: fixed;
  top: 80px;
  width: 100%;
  height: 3px;
  background-color: #084298;
  background: linear-gradient(-45deg, white,white, white,white,blue,white,white,white,white );
	background-size: 200% 200%;
	animation: gradient 4s ease-in-out infinite;
}
@keyframes gradient {
	0% {
		background-position: 0% 100%;
	}
	50% {
		background-position: 100% 100%;
	}
	100% {
		background-position: 0% 100%;
	}
}
.font-bold{
  font-family: 'Poppins-Bold';
}
.font-semi{
  font-family: 'Poppins-semiBold';
}
.font-req{
  font-family: 'Poppins-Regular';
}
.font-thin{
  font-family: 'Poppins-Thin';
}
.text-small{
  font-size: 0.6em;
}
html * {
  box-sizing: border-box;
}
.row {
  --bs-gutter-x : 0;
}
i{
  font-style: normal;
}