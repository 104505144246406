.statusBar .dropdown-menu{
    border-radius:12px ;
    box-shadow: -1px 3px 6px var(--bs-gray-400);
    inset: 0px -1px auto auto !important;
}
.statusBar .dropdown ul,.statusBar .dropdown ul li{
    background-color: var(--bs-gray-100);
}
.statusBar .dropdown ul li p{
    margin: 0;
}
.statusBar .dropdown ul li span{
    font-size: 0.9em;
    color: var(--bs-gray-600);
}
.statusBar .dropdown ul li i{
    font-size: 1.2;
    font-style: normal;
    cursor: pointer;
    padding: 0.5em;
}
.docName span:nth-child(1){
    color: var(--bs-gray-600);
}
.modalAlloShare{
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background: hsla(0,0%,100%,.2);
    backdrop-filter: blur(6.9px);
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
}
.modalAlloShare .modalBody{
    border: 1px solid var(--bs-gray-600);
    border-radius: 12px;
    background: white;
}
.modalAlloShare .modalContent{
    padding: 1.5em;
}
.navStatusBar{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 1em;
}
@media (max-width: 576px){
    .navStatusBar ul{
        position: relative;
    }
    .navStatusBar ul:not(.open) li{
        position: absolute;
        top: 0;
        width: max-content;
        background-color: white;
        z-index: 1;
    }
    .navStatusBar ul.open{
        position: absolute;
        display: flex;
        flex-direction: column;
    }
    .navStatusBar .active{
        z-index: 10 !important;
    }
}
.docName>p{
    flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.hasChange{
    background-color: rgba(255,0,0, 0.03);
    cursor: pointer;
}
.hasChange code{
 border: 1px solid var(--bs-gray-500);
 border-radius: 2px;
 padding-left: 3px;
 padding-right: 3px;
 color: inherit;
}

.reviwMarkdown{
    min-height:20px
}
.reviewActiveRow *{
    text-decoration: underline dashed 2px red;
}
.reviewSidebar{
    width: 290px;
    position: relative;
    transition: all 0.3s linear;
    flex-shrink: 0;
    padding-left: 20px;
    height: 100vh;
}
.reviewSidebar.close{
    width: 20px;
}
.r-collapser{
    display: block;
    position: absolute;
    top: calc(50% - 21px);
    left: -7px;
    width: 40px;
    height: 40px;
    font-size: 27px;
    text-align: center;
    cursor: pointer;
    z-index: 1;
}


.forCollaps{
    height: 100vh;
    background: white;
    /* position: relative; */
    border:1.5px solid var(--bs-gray-600) ;
    border-right: none;
    border-radius: 12px 0 0 12px;
    padding: 0 0.5em;
    overflow-y: auto;
}
.reviewSidebar>img{
    position: absolute;
    top: calc(50% - 30px);
    left: -0.5px;
}

.r-SidebarHead{
    position: sticky;
    top: 0;
    background-color: inherit;
    margin-bottom: 10px;
    box-shadow: 0 1px 0 var(--bs-gray-200);
    padding-top: 1.5em;
}
.mainSide{
    height: calc(100vh - 1.5rem);
    overflow-y: auto; 
}
.mainHead{
    position: sticky;
    top: 0;
    background-color: white;
    box-shadow: 0 1px 0 var(--bs-gray-200);
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;
}
.btn_mini{
    font-size: 0.8em;
    padding: 0.5em 0.6em;
    border-radius: 20px;
}